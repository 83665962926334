export default {
    id: null, // bigint NOT NULL DEFAULT nextval('authorize.role_id_seq'::regclass),
    role_name: null, // character varying COLLATE pg_catalog."default",
    superadmin: null, // boolean,
    description: null, // character varying COLLATE pg_catalog."default",
    createdby: null, // integer,
    createdtime: null, // timestamp without time zone,
    updatedby: null, // integer,
    updatedtime: null, // time without time zone,
    assignments: {
  // HIDE this, task https://gitlab.com/nusateknoglobal/purwakarta-project/pojok-aparatur/layanan-be/-/issues/3
        // uji_kompetensi: {
        //     index: 0,
        //     create: 0,
        //     update: 0,
        //     delete: 0
        // },
        // monitoring_jabatan_fungsional: {
        //     index: 0,
        //     proses: 0,
        // },
        // monitoring_jabatan_umum: {
        //     index: 0,
        //     proses: 0,
        // },
        // admin: {
        //     kualifikasi_jabatan_struktural: {
        //         index: 0,
        //         create: 0,
        //         update: 0,
        //         delete: 0
        //     },
        //     abk_jabatan_umum: {
        //         index: 0,
        //         create: 0,
        //         update: 0,
        //         delete: 0
        //     },
        //     informasi_jabatan_umum: {
        //         index: 0,
        //         create: 0,
        //         update: 0,
        //         delete: 0
        //     },
        //     abk_jabatan_fungsional: {
        //         index: 0,
        //         create: 0,
        //         update: 0,
        //         delete: 0
        //     },
        //     informasi_jabatan_functional: {
        //         index: 0,
        //         create: 0,
        //         update: 0,
        //         delete: 0
        //     },
        // },
        layanan: {
            jenis_layanan: {
                index: 0,
                create: 0,
                update: 0,
                delete: 0
            },
            persyaratan_layanan: {
                index: 0,
                create: 0,
                update: 0,
                delete: 0
            },
        },
        usulan_layanan: {
            // usulan_kenaikan_pangkat: {
            //     index: 0,
            //     create: 0,
            //     update: 0,
            //     delete: 0
            // },
            usulan_penerbitan_sk_pensiun: {
                index: 0,
                create: 0,
                update: 0,
                delete: 0
            },
            usulan_skumptk: {
                index: 0,
                create: 0,
                update: 0,
                delete: 0
            },
        // HIDE this, https://gitlab.com/nusateknoglobal/purwakarta-project/pojok-aparatur/layanan-be/-/issues/18
            // usulan_mutasi_masuk: {
            //     index: 0,
            //     create: 0,
            //     update: 0,
            //     delete: 0
            // },
            // usulan_mutasi_keluar: {
            //     index: 0,
            //     create: 0,
            //     update: 0,
            //     delete: 0
            // },
            // pencantuman_gelar: {
            //     index: 0,
            //     create: 0,
            //     update: 0,
            //     delete: 0
            // },
            // sk_kenaikan_pangkat: {
            //     index: 0,
            //     create: 0,
            //     update: 0,
            //     delete: 0
            // },
            // sk_pengangkatan: {
            //     index: 0,
            //     create: 0,
            //     update: 0,
            //     delete: 0
            // }
        },
        disposisi_layanan: {
            disposisi_penerbitan_sk_pensiun: {
                index: 0,
                proses: 0,
            },
            disposisi_skumptk: {
                index: 0,
                proses: 0,
            },
        },
        verifikasi_layanan: {
            verifikasi_penerbitan_sk_pensiun_i: {
                index: 0,
                proses: 0,
            },
            verifikasi_penerbitan_sk_pensiun_ii: {
                index: 0,
                proses: 0,
            },
            verifikasi_skumptk_i: {
                index: 0,
                proses: 0,
            },
            verifikasi_skumptk_ii: {
                index: 0,
                proses: 0,
            },
        // HIDE this, https://gitlab.com/nusateknoglobal/purwakarta-project/pojok-aparatur/layanan-be/-/issues/18
            // kenaikan_pangkat: {
            //     index: 0,
            //     proses: 0,
            // },
            // usulan_mutasi_masuk: {
            //     index: 0,
            //     proses: 0,
            // },
            // pencantuman_gelar: {
            //     index: 0,
            //     proses: 0,
            // },
            // pnp_sk_kenaikan_pangkat: {
            //     index: 0,
            //     create: 0,
            // },
            // pnp_sk_pengangkatan: {
            //     index: 0,
            //     proses: 0,
            // },
            // mutasi_keluar: {
            //     index: 0,
            //     proses: 0,
            // },
        },
        pengesahan_layanan: {
            pengesahan_penerbitan_sk_pensiun: {
                index: 0,
                proses: 0,
            },
            pengesahan_skumptk: {
                index: 0,
                proses: 0,
            },
        },
        rekapitulasi_layanan: {
            rekapitulasi_penerbitan_sk_pensiun: {
                index: 0,
            }
        },
    }
}